import HomePage from "../../pages";
import Gift from "../../pages/gift";
import GiftRelative from "../../pages/giftsRelative";
import InputPhone from "../../pages/InputPhone";


const routes = [
    {
        path: "/home",
        element: HomePage
    },
   {
        path: '/gift',
        element: Gift
    },
    {
        path: '/gift-relatives',
        element: GiftRelative
    },
    {
        path: '/',
        element:InputPhone
    }

];

export { routes };