import React from 'react';
import { images } from './assets/images';



interface IFormCodeProps {
    handleCheckCode: (code: string) => void;
    loading: boolean;
}
const FormCode = ({ handleCheckCode, loading }: IFormCodeProps) => {
    const [code, setCode] = React.useState<string>('');

    const handle = () => {

        handleCheckCode(code);
    }
    return (
        <div className="absolute top-[50%]  -translate-y-[50%] w-full left-0 px-2 flex justify-center">
            <div className="relative w-[95%]">
                <img src={images.popup_invoice} alt="" className="w-full  " />
                <div className="absolute top-[50%] left-[50%] -translate-x-[50%] w-full px-5">
                    <input style={{ marginLeft: '10%', marginTop: '7%' }} type="text" name="" id="" className="w-[80%] h-[40px] rounded text-xl px-2 py-2" value={code} onChange={(e) => setCode(e.target.value)} />
                    <div style={{ marginTop: '4%' }} className="text-center w-full  flex justify-center">
                        {/* {loading && ( */}
                            <img src={images.button_nhan_qua} alt="" className=" w-[200px] object-contain" onClick={handle} />
                        {/* )} */}
                    </div>
                </div>
            </div>

        </div>
    )

}
export default FormCode;