import React, { useState } from "react";
import { images } from "./assets/images";

interface IWithdrawGiftProps {
  handleSetStatus: (status: number) => void;
  code: string | null;
  is_tat: number;
}

const WithdrawGift: React.FunctionComponent<IWithdrawGiftProps> = ({
  handleSetStatus,
  code,
  is_tat,
}) => {
  const [isShaking, setIsShaking] = useState(false); // Trạng thái để kích hoạt rung

  const handleShakeAndCall = () => {
    setIsShaking(true); // Kích hoạt hiệu ứng rung
    setTimeout(() => {
      setIsShaking(false); // Tắt hiệu ứng rung
      handleSetStatus(4); // Gọi hàm sau khi rung xong
    }, 0); // Hiệu ứng rung kéo dài 1 giây
  };

  return (
    <>
      {/* is_tat === 2 || is_tat === 3 ? ( */}
        <div className="absolute top-[55%] -translate-y-[50%] w-full left-0">
          <div className="relative">
            {/* Hình nền popup */}
            <img
              src={images.popup_thuong_gia}
              alt=""
              className={`w-full ${isShaking ? "animate-shake" : ""}`}
            />

            {/* Nút bấm để kích hoạt call API */}
            <div className="absolute bottom-[0%] left-[50%] -translate-x-[50%] w-full px-5">
              <div className="text-center w-full flex justify-center">
                <img
                  onClick={handleShakeAndCall}
                  src={images.btn_rut_lixi}
                  className="w-[200px]"
                />
              </div>
            </div>
          </div>
        </div>
      {/* ) : (
        <div className="absolute top-[55%] -translate-y-[50%] w-full left-0">
          <div className="relative">
            <img
              src={images.popup_pho_thong}
              alt=""
              className={`w-full ${isShaking ? "animate-shake" : ""}`}
            />
            <div className="absolute bottom-[0%] left-[50%] -translate-x-[50%] w-full px-5">
              <div className="text-center w-full flex justify-center">
                <img
                  onClick={handleShakeAndCall}
                  src={images.btn_rut_lixi}
                  className="w-[200px]"
                />
              </div>
            </div>
          </div>
        </div>
      ) */}

      {/* CSS Custom */}
      <style>
        {`
          @keyframes shake {
            0%, 100% {
              transform: translateX(0);
            }
            10% {
              transform: translateX(-15px);
            }
            20% {
              transform: translateX(15px);
            }
            30% {
              transform: translateX(-20px);
            }
            40% {
              transform: translateX(20px);
            }
            50% {
              transform: translateX(-25px);
            }
            60% {
              transform: translateX(25px);
            }
            70% {
              transform: translateX(-15px);
            }
            80% {
              transform: translateX(15px);
            }
            90% {
              transform: translateX(-10px);
            }
          }

          .animate-shake {
            animation: shake 2s cubic-bezier(0.36, 0.07, 0.19, 0.97); /* Hiệu ứng 2 giây */
          }
        `}
      </style>
    </>
  );
};

export default WithdrawGift;
