import React, { Suspense, useEffect, useState } from "react";
import { List, Page, Icon, useSnackbar, Sheet as ZMPSheet } from "zmp-ui";
import { images } from "../components/assets/images";
import FormCode from "../components/form_code";
import WithdrawGift from "../components/withdrawGift";
import WithdrawDoneGift from "../components/withdrawDoneGift";
import WithdrawGiftLixi from "../components/withdrawGiftLixi";
import { AppContext } from "../components/context/MyContext";

import axios from "axios";
import { api, app_id, baseUrl, IdOa, requests, secret_key } from "../api";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { CheckWithdrawal } from "../types/types";
import Swal from "sweetalert2";
import handleCopyLink, { Toast } from "../components/uitilities";

const HomePage: React.FunctionComponent = () => {
    const navigate = useNavigate();
    const [is_checked_code, setIsCheckedCode] = React.useState(false);
    const [code, setCode] = React.useState<string>("");
    const [is_tat, setIs_tat] = React.useState(0);
    const { user, phoneUser, setPhoneUser, setUser } = React.useContext(AppContext);
    const [statusGiftUser, setStatusGiftUser] = React.useState<number | null>(null);
    const [typeGift, setTypeGift] = React.useState(1);
    const [listNumberLive, setListNumberLive] = React.useState([]);
    const [codeGift, setCodeGift] = React.useState('0');
    const [id_user_gift, setId_user_gift] = React.useState(0);
    const [status, setStatus] = useState(false);
    const [loading, setLoading] = useState(false);
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const phone = searchParams.get("phone");
    const handleDrawGifts = (name: string) => {
        navigate("/gift", { state: { name, code } });
        navigate("/home");
    };
    const handleSetStatus = (status: number) => {
        setStatusGiftUser(status);
    };
    useEffect(() => {
        console.log("phone", phone);
        if (phone) {
            setPhoneUser(phone);
        }
    }, [phone]);




    const handleCheckCode = async (code: string) => {
        console.log("code", code);
        setLoading(true);
        if (!code) {
            Toast({ type: 'error', message: 'Vui lòng nhập mã hóa đơn' });
            return;
        }
        try {
            if (phone) {
                const response = await axios.get(api.checkInvoiceTet(), {
                    params: {
                        code_invoice: code,
                        phone: phone,
                    },
                });
                console.log('sau khi đã check hoá đơn: ', response.data);
                if (response.data.data !== 1) {
                    Toast({ type: 'error', message: response.data.message });
                    checkWithdrawGift();
                    setLoading(false);
                } else {
                    Toast({ type: 'success', message: response.data.message });
                    checkWithdrawGift();
                    setCode(code);
                    setIsCheckedCode(true);
                    setLoading(false);
                }
            }
        } catch (error) {
            console.error("Error checking invoice code:", error);
            Toast({ type: 'error', message: 'Đã xảy ra lỗi khi kiểm tra mã hóa đơn' });

        }
    };

    const handleApiCallUpdateGift = async () => {
        console.log('id_user_gift trong hàm: ',id_user_gift);
        try {
          const response = await fetch(
            'https://minigame3tot.winwingroup.vn/api/mini-game-tet/use-lixi',
            {
              method: 'POST',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify({ id_user_gift: id_user_gift }), // Mã hoá đơn
            }
          );
    
          if (!response.ok) throw new Error('Call API thất bại!');
          const data = await response.json();
          console.error('Dữ liệu khi đã update:', data);
          if(data.status == 1){
            setTypeGift(1);
            checkWithdrawGift();
            Toast({ type: 'success', message: data.msg });
          }else{
            Toast({ type: 'error', message: data.msg });
          }
          
        } catch (error) {
          console.error('Lỗi khi gọi API:', error);
        } finally {
        }
      };

    // Check if the user has already withdrawn a gift
    const checkWithdrawGift = async () => {
        try {
            console.log(phone);
            const res = await axios.get(api.checkWithdrawGiftTet(), {
                params: { phone: phone },
            });
            console.log('check data withdraw res.data :', res.data);
            console.log('check data withdraw res.data.status :', res.data.status);
            setCodeGift(res.data?.gift?.code_gift || '0');
            setListNumberLive(res.data?.list_number || []);
            setTypeGift(res.data?.type_gift ?? 1);
            setId_user_gift(res.data?.gift?.id_user_gift ?? 0);
            console.log('có chạy quay đoạn xét dữ liệu: ',res.data);
            switch (res.data.status) {
                case 1:
                    setStatusGiftUser(1);
                    break;
                case 2:
                    setStatusGiftUser(2);
                    setIsCheckedCode(true);
                    setIs_tat(res.data?.info_withdraw?.type_ticket_class);
                    setCode(res.data.info_withdraw.code_invoice_kiotviet);
                    break;
                case 3:
                    setStatusGiftUser(3);
                    setCode(res.data.info_withdraw.code_invoice_kiotviet);
                    setIs_tat(res.data?.info_withdraw?.type_ticket_class)
                    setIsCheckedCode(true);
                    break;
                case 0:
                    setStatusGiftUser(0);
                    break;
                default:
                    console.log('Đã sét setStatusGiftUser = default');
                    break;
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        checkWithdrawGift();
    }, [phoneUser, phone, code]);

    useEffect(() => {
        console.log('statusGiftUser: ', statusGiftUser);
        console.log('Mã hoá đơn : ', code);
    }, [statusGiftUser]);
    return (
        <Page style={{ backgroundImage: `url(${images.bg_tet})`, backgroundSize: 'cover' }} className="page bg-main relative  h-full min-h-screen">
            <div className="absolute top-[10%] z-10 w-full flex justify-center left-[-5%]">
                <img src={images.logo_noel} alt="" className="w-[80%]" />
            </div>
            {
                statusGiftUser === 2 && (
                    is_checked_code ?
                        <WithdrawGift is_tat={is_tat}  handleSetStatus={handleSetStatus} code={phone} /> :
                        <FormCode handleCheckCode={handleCheckCode} loading={loading}/>
                )
            }
            {
                (statusGiftUser === null || statusGiftUser === 1) && (
                    <FormCode handleCheckCode={handleCheckCode} loading={loading}/>
                )
            }
            {
                statusGiftUser === 3 && (
                    is_checked_code &&
                    <WithdrawGift is_tat={is_tat} handleSetStatus={handleSetStatus} code={code} />
                )
            }
            {
                statusGiftUser === 0 && (
                    <WithdrawDoneGift
                        typeGift={typeGift}
                        codeGift={codeGift}
                        listNumberLive={listNumberLive}
                        status={status}
                        setStatus={setStatus}
                        handleApiCallUpdateGift={handleApiCallUpdateGift}
                    />
                )
            }
            {
                statusGiftUser === 4 && (
                    <WithdrawGiftLixi
                        is_tat={is_tat} code={phone}
                        checkWithdrawGift={checkWithdrawGift}
                    />
                )
            }

        </Page>
    );
};

export default HomePage;
