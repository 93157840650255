import React, { useState } from 'react';
import { images } from './assets/images';

interface IListNumberLive {
  id: number;
  phone: string;
  code: number;
  name: string;
  app_id: string;
  product_name: string;
  product_image: string;
  product_id: number;
  address: string;
  shop_name: string;
  shop_address: string;
  status: number;
  created_at: string;
  updated_at: string;
}

interface IWithdrawGiftProps {
  listNumberLive: IListNumberLive[];
  codeGift: string;
  typeGift: Number;
  status: boolean; // Trạng thái để bật/tắt popup
  setStatus: (value: boolean) => void;
  handleApiCallUpdateGift: () => void;
}
const WithdrawDoneGift: React.FunctionComponent<IWithdrawGiftProps> = ({ listNumberLive, codeGift, typeGift, handleApiCallUpdateGift, status,
  setStatus, }) => {
  const [listNumber, setListNumber] = useState<IListNumberLive[]>(listNumberLive);
  const [code, setCode] = useState<string>(codeGift);
  const [type_gift, setTypeGift] = useState(typeGift);
  console.log('code cccccccc: ', type_gift);

  const handle = () => {
    setStatus(true);
  }
  const handleAgree = () => {
    handleApiCallUpdateGift(); // Gọi API
    setStatus(false); // Đóng popup sau khi người dùng đồng ý
  };

  const handleDisagree = () => {
    setStatus(false); // Đóng popup nếu người dùng không đồng ý
  };
  return (
    <>
      {/* Popup */}
      {status && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-[300px] text-center">
            <h3 className="text-lg font-bold mb-4">Xác nhận sử dụng Voucher</h3>
            <p className="mb-6">Bạn có chắc chắn muốn thực hiện hành động này?</p>
            <div className="flex justify-between">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={handleDisagree}
              >
                Không đồng ý
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md"
                onClick={handleAgree}
              >
                Đồng ý
              </button>
            </div>
          </div>
        </div>
      )}
      {/* <div className="absolute top-[52%] -translate-y-[50%] w-full left-0">
        <div className="relative">
          <img src={images.popop_trung_qua} alt="" className="w-full" />
          <div className="absolute w-full top-[50%] -translate-y-1/2">
            <div className="flex flex-col items-center justify-center gap-2">
              {listNumber?.map((item, index) => (
                <div
                  key={item.id}
                  className="relative flex items-center bg-white border-4 border-yellow-500 rounded-full px-6 mx-2"
                >
                  <div className="absolute top-[27%] left-0 transform -translate-x-1/2 bg-red-500 border-4 border-yellow-500 w-[15px] h-[15px] rounded-full"></div>
                  <div className="flex space-x-2 text-black font-bold text-xl tracking-wide">
                    {String(item.code).split('').map((digit, i) => (
                      <span key={i}>{digit}</span>
                    ))}
                  </div>
                  <div className="absolute right-0 transform translate-x-1/2 bg-red-500 border-4 border-yellow-500 w-[15px] h-[15px] rounded-full"></div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div> */}

      <div className="absolute top-[50%]  w-full left-0">
        <div className="relative w-[95%] bottom-[0px] left-[5%] " >

          {
            code === '20k'
              ?
              <img src={images._20k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
              :
              <></>
          }
          {
            code === '50k'
              ?
              <img src={images._50k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
              :
              <></>
          }
          {
            code === '100k'
              ?
              <img src={images._100k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
              :
              <></>
          }
          {
            code === '200k'
              ?
              <img src={images._200k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
              :
              <></>
          }
          {
            code === '500k'
              ?
              <img src={images._500k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
              :
              <></>
          }
          {/* {
            typeGift === 0 ?
              <div style={{ marginTop: '3%' }} className="text-center w-full  flex justify-center">
                <img src={images.btn_sudung_lixi} alt="" className=" w-[90%] object-contain" onClick={handle} />
              </div> :
              <div style={{ marginTop: '3%' }} className="text-center w-full  flex justify-center">
                <img src={images.btn_donelixi} alt="" className=" w-[90%] object-contain" />
              </div>
          } */}

        </div>
      </div>
    </>
  );
};

export default WithdrawDoneGift;
