import banner_mini_gamer from './banner-mini-game.png'
import nhap_ma_hoa_don from './nha-ma-hoa-don.png'
import bg_bottom_left from './bg-bottom-left.png'
import bg_bottom_right from './bg-bottom-right.png'
import bg_top_right from './bg-top-right.png'
import button_nhan_qua from './button-nhan-qua.png'
import thu_cam_on from './thu-cam-on.png'
import btn_tu_rut from './btn-tu-rut.png'
import btn_nguoi_than_rut from './bnt-nguoi-than-rut.png'
import btn_bg_qua_da_rut from './btn-bg-qua-da-rut.png'
import text_qua_da_rut from './text-qua-da-rut.png'
import qua_nguoi_than from './qua-nguoi-than.png'
import qua_tu_rut from './qua-tu-rut.png'
import gift_1 from './gift_1.png'
import bg_form_sdt_nguoi_than from './bg-form-sdt-nguoi-than.png'
import btn_gui_nguoi_than from './btn-gui-nguoi-than.png'
import btn_rut_qua from './btn-rut-qua.png'
import btb_gui from './btn-gui.png'
import box_gift from './box_gift.png';
import btn_lam_moi from './btn-lam-moi.png'
import thong_diep from './thong-diep.png'
import bg_noel from './bg-noel.png'
import logo_noel from './logo_3tot_giangsinh.png'
import popup_invoice from './popup-invoice-tet.png'
import input_hoadon from './input_hoadon.png'
import popup_rutso from './popup_rutso.png'
import button_rutqua from './button_rutqua.png'
import rut_thanh_cong from './rut_thanh_cong.png'
import popop_trung_qua from './popop_trung_qua.png'
import popup_thuong_gia from './popup_thuong_gia.png'
import popup_pho_thong from './popup_pho_thong.png'
import btn_rut_lixi from './btn_rut_lixi.png'
import lixi_vang from './lixi_vang.png'
import lixi_bac from './lixi_bac.png'
import btn_mo_lixi from './btn_mo_lixi.png'
import btn_sudung_lixi from './btn_sudung_lixi.png'
import btn_donelixi from './btn_donelixi.png'
import btn_goback from './btn_goback.png'

import _0 from './0.png'
import _1 from './1.png'
import _2 from './2.png'
import _3 from './3.png'
import _4 from './4.png'
import _5 from './5.png'
import _6 from './6.png'
import _7 from './7.png'
import _8 from './8.png'
import _9 from './9.png'
import _20k from './20k.png'
import _50k from './50k.png'
import _100k from './100k.png'
import _200k from './200k.png'
import _500k from './500k.png'
import khung_so from './khung_so.png'
import popop_trung_tat from './popop_trung_tat.png'
import bg_tet from './bg-tet.png'
const images = {
    banner_mini_gamer,
    nhap_ma_hoa_don,
    bg_bottom_left,
    bg_bottom_right,
    bg_top_right,
    button_nhan_qua,
    thu_cam_on,
    btn_tu_rut,
    btn_nguoi_than_rut,
    btn_bg_qua_da_rut,
    text_qua_da_rut,
    qua_nguoi_than,
    qua_tu_rut,
    gift_1,
    bg_form_sdt_nguoi_than,
    btn_gui_nguoi_than,
    btn_rut_qua,
    btb_gui,
    box_gift,
    btn_lam_moi,
    thong_diep,
    bg_noel,
    logo_noel,
    popup_invoice,
    input_hoadon,
    popup_rutso,
    button_rutqua,
    khung_so,
    rut_thanh_cong,
    popop_trung_qua,
    popop_trung_tat,
    _0,
    _1,
    _2,
    _3,
    _4,
    _5,
    _6,
    _7,
    _8,
    _9,
    _20k,
    _50k,
    _100k,
    _500k,
    _200k,
    bg_tet,
    popup_pho_thong,
    popup_thuong_gia,
    btn_rut_lixi,
    lixi_vang,
    lixi_bac,
    btn_mo_lixi,
    btn_sudung_lixi,
    btn_donelixi,
    btn_goback

}
export { images }