import {Page, useSnackbar} from "zmp-ui";
import {useState} from "react";
import {useNavigate} from "react-router-dom";
import {Toast} from "../components/uitilities";
import { images } from '../components/assets/images';
const InputPhone = () => {
const  [phone, setPhone] = useState<string|null>(null);
const nagivate = useNavigate();

const handleLogin = () => {
    if (phone !== null && /^\d{10}$/.test(phone.toString())) {
        nagivate('/home?phone=' + phone);
    } else {
        Toast({type: 'error', message: 'Số điện thoại không hợp lệ'});
    }
};
    return (
        <Page style={{ backgroundImage: `url(${images.bg_tet})`, backgroundSize: 'cover' }} className={' page bg-main relative  h-full min-h-screen'}>

            <div className={'flex flex-col justify-center items-center min-h-screen'}>
                <div className="p-5  w-full ">
                    <div className="p-5 w-full bg-white rounded bg-form-input bg-opacity-25">
                        <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
                            <div className="text-center text-2xl  element font-1">
                                Vui lòng nhập số điện thoại
                            </div>
                                <div>

                                    <div className="mt-2">
                                        <input id="phone" name="phone" type="tel" required
                                           onChange={(e) => setPhone((e.target.value))}
                                               className="px-3 text-xl  block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"/>
                                    </div>
                                </div>

                                <div>


                                </div>

                                <div className={'mt-5'}>
                                    <button type="button"
                                            style={{ backgroundColor: '#db9b00' }}  
                                            onClick={()=>handleLogin()}
                                            className="mt- 5 flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600">Tham gia Mini Game
                                    </button>
                                </div>


                        </div>
                    </div>

                </div>

            </div>

        </Page>

    )

}
export default InputPhone