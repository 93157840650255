import React, { useState } from 'react';
import { images } from './assets/images';
import handleCopyLink, { Toast } from "../components/uitilities";
import { Link, useLocation, useNavigate } from "react-router-dom";
interface IWithdrawGiftProps {
  code: string | null;
  is_tat: Number;
  checkWithdrawGift: () => void;
}

const WithdrawGiftLixi: React.FunctionComponent<IWithdrawGiftProps> = ({ code, is_tat, checkWithdrawGift }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [codeGift, setCodeGift] = useState<string>('0');
  const [idUserGgift, setIdUserGgift] = useState<number>(0);
  const [typeGift, setTypeGift] = useState<number>();
  const [status, setStatus] = useState(false);
  const [isShaking, setIsShaking] = useState(false); // Trạng thái để kích hoạt rung

  const handleShakeAndCall = () => {
    setIsShaking(true); // Kích hoạt hiệu ứng rung
    setTimeout(() => {
      setIsShaking(false); // Tắt hiệu ứng rung
      handleApiCall(); // Gọi hàm sau khi rung xong
    }, 2000); // Hiệu ứng rung kéo dài 1 giây
  };
  const handleGoHome = () => {
    navigate("/home");
  };
  const handleApiCall = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        'https://minigame3tot.winwingroup.vn/api/mini-game-tet/withdraw-gifts-tet',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ code: code }), // Mã hoá đơn
        }
      );

      if (!response.ok) throw new Error('Call API thất bại!');

      const data = await response.json();
      console.log('Data khi rút: ', data);
      console.log('Mã hoá đơn là: ', code);
      setIdUserGgift(data.gift.id_user_gift);
      setCodeGift(data.gift.code_gift);
      setTypeGift(data?.type_gift);
      checkWithdrawGift();
    } catch (error) {
      console.error('Lỗi khi gọi API:', error);
    } finally {
      setLoading(false);
    }
  };
  const handleApiCallUpdateGift = async () => {
    setLoading(true);
    console.log('idUserGgift trong hàm cập nhật quà là: ', idUserGgift);
    try {
      const response = await fetch(
        'https://minigame3tot.winwingroup.vn/api/mini-game-tet/use-lixi',
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ id_user_gift: idUserGgift }), // Mã hoá đơn
        }
      );

      if (!response.ok) throw new Error('Call API thất bại!');
      const data = await response.json();
      console.error('Dữ liệu khi đã update:', data);
      if (data.status == 1) {
        setTypeGift(1);
        Toast({ type: 'success', message: data.msg });
        // navigate("/home");
      } else {
        Toast({ type: 'error', message: data.msg });
      }

    } catch (error) {
      console.error('Lỗi khi gọi API:', error);
    } finally {
      setLoading(false);
    }
  };
  const handle = () => {
    setStatus(true);
  }
  const handleAgree = () => {
    handleApiCallUpdateGift(); // Gọi API
    setStatus(false); // Đóng popup sau khi người dùng đồng ý
  };

  const handleDisagree = () => {
    setStatus(false); // Đóng popup nếu người dùng không đồng ý
  };
  console.log('giá trị của is_tat', is_tat)
  return (
    <>
      {/* Popup */}
      {status && (
        <div className="fixed top-0 left-0 w-full h-full flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg p-6 w-[300px] text-center">
            <h3 className="text-lg font-bold mb-4">Xác nhận sử dụng Voucher</h3>
            <p className="mb-6">Bạn có chắc chắn muốn thực hiện hành động này?</p>
            <div className="flex justify-between">
              <button
                className="bg-red-500 text-white px-4 py-2 rounded-md"
                onClick={handleDisagree}
              >
                Không đồng ý
              </button>
              <button
                className="bg-green-500 text-white px-4 py-2 rounded-md"
                onClick={handleAgree}
              >
                Đồng ý
              </button>
            </div>
          </div>
        </div>
      )}
      
        {/* is_tat == 2 || is_tat == 3
          ? */}
          <div className="absolute top-[55%] -translate-y-[50%] w-full left-0">
            {/* CSS Custom */}
            <style>
              {`
                @keyframes shake {
                  0%, 100% {
                    transform: translateX(0);
                  }
                  10% {
                    transform: translateX(-15px);
                  }
                  20% {
                    transform: translateX(15px);
                  }
                  30% {
                    transform: translateX(-20px);
                  }
                  40% {
                    transform: translateX(20px);
                  }
                  50% {
                    transform: translateX(-25px);
                  }
                  60% {
                    transform: translateX(25px);
                  }
                  70% {
                    transform: translateX(-15px);
                  }
                  80% {
                    transform: translateX(15px);
                  }
                  90% {
                    transform: translateX(-10px);
                  }
                }

                .animate-shake {
                  animation: shake 2s cubic-bezier(0.36, 0.07, 0.19, 0.97); /* Hiệu ứng 2 giây */
                }
              `}
            </style>
            {
              codeGift === '0' ?
                <div className="relative">
                  {/* Hình nền popup */}
                  <img src={images.lixi_vang} alt="" className={`w-full ${isShaking ? "animate-shake" : ""}`} />

                  {/* Nút bấm để kích hoạt call API */}
                  <div className="absolute bottom-[10%] left-[50%] -translate-x-[50%] w-full px-5">
                    <div className="text-center w-full flex justify-center">
                      <img
                        onClick={handleShakeAndCall}
                        src={images.btn_mo_lixi}
                        className="w-[200px] "

                      />
                    </div>
                  </div>
                </div>
                :
                <div className="relative w-[95%] bottom-[20px] left-[5%] " >
                  {
                    codeGift === '100k'
                      ?
                      <>
                        <img src={images._100k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
                        <div style={{ marginTop: '3%' }} className="text-center w-full  flex justify-center">
                          {typeGift === 0 ? (
                            <img src={images.btn_sudung_lixi} alt="" className=" w-[90%] object-contain" onClick={handle} />
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <img src={images.btn_donelixi} alt="" className=" w-[90%] object-contain" />
                              <img src={images.btn_goback} alt="" className=" w-[90%] object-contain" onClick={checkWithdrawGift} />
                            </div>
                          )}
                        </div>
                      </>
                      :
                      <></>
                  }
                  {
                    codeGift === '200k'
                      ?
                      <>
                        <img src={images._200k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
                        <div style={{ marginTop: '3%' }} className="text-center w-full  flex justify-center">
                          {typeGift === 0 ? (
                            <img src={images.btn_sudung_lixi} alt="" className=" w-[90%] object-contain" onClick={handle} />
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <img src={images.btn_donelixi} alt="" className=" w-[90%] object-contain" />
                              <img src={images.btn_goback} alt="" className=" w-[90%] object-contain" onClick={checkWithdrawGift} />
                            </div>
                          )}
                        </div>
                      </>
                      :
                      <></>
                  }
                  {
                    codeGift === '500k'
                      ?
                      <>
                        <img src={images._500k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
                        <div style={{ marginTop: '3%' }} className="text-center w-full  flex justify-center">
                          {typeGift === 0 ? (
                            <img src={images.btn_sudung_lixi} alt="" className=" w-[90%] object-contain" onClick={handle} />
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <img src={images.btn_donelixi} alt="" className=" w-[90%] object-contain" />
                              <img src={images.btn_goback} alt="" className=" w-[90%] object-contain" onClick={checkWithdrawGift} />
                            </div>
                          )}
                        </div>
                      </>
                      :
                      <></>
                  }
                </div>
            }
          </div>
          {/* :
          <div className="absolute top-[55%] -translate-y-[50%] w-full left-0">
            {
              codeGift === '0' ?
                <div className="relative">
                  <img src={images.lixi_bac} alt="" className={`w-full ${isShaking ? "animate-shake" : ""}`} />
                  <div className="absolute bottom-[10%] left-[50%] -translate-x-[50%] w-full px-5">
                    <div className="text-center w-full flex justify-center">
                      <img
                        onClick={handleShakeAndCall}
                        src={images.btn_mo_lixi}
                        className="w-[200px] "
                      />
                    </div>
                  </div>
                </div>
                :
                <div className="relative w-[95%] bottom-[20px] left-[5%] " >
                  {
                    codeGift === '20k'
                      ?
                      <>
                        <img src={images._20k} alt="" className="w-[95%] text-center outline outline-2 flex flex-col items-center justify-center outline-yellow-500 outline-offset-2" />
                        <div style={{ marginTop: '3%' }} className="text-center w-full  flex justify-center">
                          {typeGift === 0 ? (
                            <img src={images.btn_sudung_lixi} alt="" className=" w-[90%] object-contain" onClick={handle} />
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <img src={images.btn_donelixi} alt="" className=" w-[90%] object-contain" />
                              <img src={images.btn_goback} alt="" className=" w-[90%] object-contain" onClick={checkWithdrawGift} />
                            </div>

                          )}
                        </div>
                      </>
                      :
                      <></>
                  }
                  {
                    codeGift === '50k'
                      ?
                      <>
                        <img src={images._50k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
                        <div style={{ marginTop: '3%' }} className="text-center w-full  flex justify-center">
                          {typeGift === 0 ? (
                            <img src={images.btn_sudung_lixi} alt="" className=" w-[90%] object-contain" onClick={handle} />
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <img src={images.btn_donelixi} alt="" className=" w-[90%] object-contain" />
                              <img src={images.btn_goback} alt="" className=" w-[90%] object-contain" onClick={checkWithdrawGift} />
                            </div>
                          )}
                        </div>
                      </>
                      :
                      <></>
                  }
                  {
                    codeGift === '500k'
                      ?
                      <>
                        <img src={images._500k} alt="" className="w-[95%] outline outline-2 outline-yellow-500 outline-offset-2" />
                        <div style={{ marginTop: '3%' }} className="text-center w-full  flex justify-center">
                          {typeGift === 0 ? (
                            <img src={images.btn_sudung_lixi} alt="" className=" w-[90%] object-contain" onClick={handle} />
                          ) : (
                            <div className="flex flex-col items-center justify-center">
                              <img src={images.btn_donelixi} alt="" className=" w-[90%] object-contain" />
                              <img src={images.btn_goback} alt="" className=" w-[90%] object-contain" onClick={checkWithdrawGift} />
                            </div>
                          )}
                        </div>
                      </>
                      :
                      <></>
                  }
                </div>
            }
          </div> */}

      

    </>
  );
};

export default WithdrawGiftLixi;
